import React from "react"
import { Link, navigate } from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import {Container, Button, Form, Message, Header} from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'
import {
	confirmationColor,
	backColor
} from '../globalVar'
import SignInOTP from '../components/Forms/signInOTPChangePassword'

const Negosyo = require('../../lib/negosyo')


class Reset extends React.Component {
	state = {
		contact: '',
		loading:false,
		stage: 0,
		error: '',
		errorMessage: {
			contact:undefined,
		},
	}

	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===true) {
				//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
				navigate(
					`/my-account`,
					{
						replace:true,
						state:{
							customKey:this.props.location.key
						}
					}
				)
			}
		})//doesn't need error since isLoggedIn won't reject promise but will only resolve with true if logged in or false
	}

	/*
	componentWillUnmount() {
		clearTimeout(this.state.timeOut1)
	}
	*/

	handleInputChange = (e, {name, value}) => {
		value = value.replace(/\s/g,"");

		this.setState({ [name]: value })
		if(value==='') {
			const message = "this must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	validateInput = () => {
		const {
			stage,
		} = this.state

		if(stage===0) {
			var contactval = ''
			if(this.state.contact !== undefined ) {
				this.setState({
					contact:this.state.contact.trim()
				})
				contactval = this.state.contact.trim()
			}

			const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
			const result = pattern.test(contactval)

			if(contactval ===''  || this.state.contact === undefined ) {
				this.setState(prevState => ({
					errorMessage: {
						...prevState.errorMessage,
						contact : "this must not be blank"
					}
				}))
				return false
			} else if(!result) {

				this.setState(prevState => ({
					errorMessage: {
						...prevState.errorMessage,
						contact : "must be a valid mobile number"
					}
				}))
				return false
			}else {
				this.setState(prevState => ({
					errorMessage: {
						...prevState.errorMessage,
						contact : undefined
					}
				}))
				return true
			}
		}
	}

	initialRequest = () => {
		this.setState({
			loading:true
		})
		const {
			contact,
		} = this.state
		if(this.validateInput()) {


			Negosyo.getUser(contact)
			.then((data)=>{
				if(data.status==="Active") {
					if(data.logintype==="Password") {
						this.setState({stage:1, error:"", loading:false})
						/*
						Negosyo.resendNegosyoCode(contact, data.username)
						.then((data)=>{
							if(data.status==="OK") {
								//this.setState({countdown:60})
								//alert(data.message)
								this.setState({stage:1, error:"", loading:false})
							}else if(data.status==="Error") {
								this.setState({error:data.message, loading:false})
							}

						})
						.catch(e =>{
							this.setState({error:e.message, loading:false})
							//console.log("error in resending code " + JSON.stringify(e))
						})
						*/
					}else {
						this.setState({error:"There is no account with that contact detail", loading:false})
					}

				}else if(data.status==="Inactive"){
					/* this whole register api call is a temporary/stopgap fix for the missing aws bug/Inactive bug where
					 * when creating an account the aws register or the sign in with otp failed and wasn't handled properly
					 */
					if(data.logintype==="Password") {
						const password = ( Math.floor(Math.random()*9000000)+1000000 ).toString()
						Negosyo.register(data.username, password )
						.then((temp)=>{
							this.setState({stage:1, error:"", loading:false})
						}).catch(e =>{
							if(
								e.message.toLowerCase()==="invalid lambda function output : invalid json" ||
								e.message.toLowerCase()==="user already exists"
							){
								this.setState({stage:1, error:"", loading:false})
							}else {
								this.setState({
									error:"Error occurred, please try again",
									loading:false
								})
							}
						})
					}else {
						this.setState({error:"There is no account with that contact detail", loading:false})
					}


					/*
					setTimeout(()=>{
						//navigate("/register/")
					}, 3000)
					*/
					//navigate("/register/")
				}else if(data.status==="None"){
					this.setState({
						error:"There is no account with that contact detail",
						loading:false
					})
				}else { //status === error and whatever else
					this.setState({error:"Error occurred, please try again", loading:false})
				}
			}).catch((err)=>{

				this.setState({error:"Error occurred, please try again", loading:false})
			})
		}else {
			this.setState({
				loading:false
			})
		}

	}

	render() {

		const {
			handleInputChange,
			initialRequest,
		} = this
		const {
			contact,
			stage,
			loading,
			errorMessage
		} =this.state
		const {
			location
		} = this.props
		return (
			<Layout location={location}>
				<Seo title={"Forgot Password"} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						{ stage === 0 && (
							<React.Fragment>
								<Header className={styles.header}>Forgot Password</Header>

								<Form onSubmit={initialRequest}>
									<Form.Input
										label ="Mobile #"
										placeholder='Mobile #'
										value={contact}
										error={errorMessage.contact}
										name='contact'
										onChange={handleInputChange}
										className={`${styles.form} ${styles.formShortened}`}
									/>
									{this.state.error &&
										<Message negative className={`${styles.msg} ${styles.centerText}`}>
											<Message.Header>{this.state.error}</Message.Header>
										</Message>
									}
									<Button
										color={backColor}
										className={styles.form}
										type="button"
										as={Link}
										to="/sign-in/"
									>
										Back
									</Button>
									<Button
										color={confirmationColor}
										className={styles.mainButton}
										disabled={loading}
										loading={loading}
									>
										Send Reset Authorization Code
									</Button>
								</Form>

							</React.Fragment>

						)}
						{ stage === 1 && (
							<SignInOTP
								header={"Forgot Password"}
								contact={contact}
								resendCode={true}
								backFunc={()=>{
									navigate('/sign-in/')
								}}
								location={location}
							/>

						)}
					</div>
				</Container>
			</Layout>
		)
	}
}



export default Reset


/*
<React.Fragment>
							<Form onSubmit={loginOTP}>
								<Form.Input
									label ="Mobile #"
									placeholder='Mobile #'
									value={contact}
									name='contact'
									onChange={handleInputChange}
									disabled
									className={styles.form}
								/>

								<Form.Input
									label="Authorization Code"
									placeholder="Authorization Code"
									onChange={handleInputChange}
									error={errorMessage.auth_code}
									value={auth_code}
									name='auth_code'
									action={{
										content: 'Resend Code',
										onClick: resendCode,
										type:"button"
									}}
									className={styles.form}
								/>
								<Button
									content={"Back"}
									color={backColor}
									disabled={loading}
									className={styles.form}
									onClick={()=>{
										this.setState({stage:0})
									}}
									type="button"
								/>
								<Button
									content={"Proceed"}
									color={confirmationColor}
									loading={loading}
									disabled={loading}
									className={styles.mainButton}
								/>
							</Form>
							<br/>
						</React.Fragment>
*/


